<ul class="user-menu-wrapper">
  <li class="nav-item profile" *ngIf="isLoggedIn">
    <div ngbDropdown class="d-inline-block-OLD">
      <button 
        data-cy="my-account-header-btn"
        class="btn nav-link notd-account-btn" 
        ngbDropdownToggle>
        <img-lazy-loading
          class="avatar"
          *ngIf="user && user.profileImageUrl"
          [mainThumb]="user.profileImageUrl"
          [thumbs]="user.profileImgThbs"></img-lazy-loading>
        <img *ngIf="!user.profileImageUrl" class="rounded" [src]="'assets/svg/user.svg'" />
        <!-- <span i18n="@@GLOBAL-MY-ACCOUNT">My Account</span>
        <fa-icon [icon]="['fas', 'angle-down']"></fa-icon> -->
      </button>
      <ul ngbDropdownMenu class="notd-dropdown-menu profile-submenu">
        <li *ngIf="loadingStreams || !userStreams">
          <button class="dropdown-item" >
            <fa-icon [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
            <span i18n="@@USER-NAV-003">Loading streams</span>
          </button>
        </li>
        <li
          class="touch-device--hide" 
          *ngIf="userStreams && userStreams.length === 1"
            [routerLink]="['/streams']">
          <button class="dropdown-item" >
            <fa-icon [icon]="['fas', 'stream']"></fa-icon>
            <span  i18n="@@GLOBAL-STREAM-INFO">Stream information</span>
          </button>
        </li>
        <li
          class="touch-device--show"
          *ngIf="userStreams && userStreams.length > 0"
            [routerLink]="['/streams']">
          <button class="dropdown-item">
            <fa-icon [icon]="['fas', 'stream']"></fa-icon>
            <span>Your streams</span>
          </button>
        </li>
        <li
          class="touch-device--hide" 
          *ngIf="userStreams && userStreams.length > 1">
          <button data-cy="stream-inf-dropdown" class="dropdown-item" (click)="userStreamsClick($event)">
            <fa-icon class="hidden-md-down" [icon]="['fas', 'chevron-left']"></fa-icon>
            <span i18n="@@GLOBAL-STREAM-INFO">Stream information</span>
          </button>
          <ul *ngIf="userStreams" class="stream-list">
            <li *ngFor = "let stream of userStreams | sortBy: ['_community', 'name']" [routerLinkActive]="'is-active'">
              <a
                [routerLink]="stream.shortURLPath ? ['/s', stream.shortURLPath] : ['/streams', stream.id, 'view', stream.indexedName]" class="stream-info">
                <div class="avatar-img">
                  <img *ngIf="stream.iconImageUrl" src="{{stream.iconImageUrl}}" />
                  <img *ngIf="!stream.iconImageUrl" src="../../../assets/logo-notd.png" />
                </div>
                <div>{{stream.name}}</div>
              </a>
              <fa-icon *ngIf="stream._community" class="community-stream" [icon]="['fas', 'user-plus']"></fa-icon>
            </li>
          </ul>
        </li>
        <li>
          <button data-cy="my-account-dropdown" class="dropdown-item" routerLink="/user/{{user.id}}/my-account">
            <fa-icon [icon]="['fas', 'user']"></fa-icon>
            <span i18n="@@GLOBAL-MY-ACCOUNT">My Account</span>
          </button>
        </li>
        <li>
          <button data-cy="my-notes-dropdown" class="dropdown-item" routerLink="/user/my-notes">
            <fa-icon [icon]="['far', 'sticky-note']"></fa-icon>
            <span>My Notes</span>
          </button>
        </li>
        <li>
          <button data-cy="my-notes-dropdown" class="dropdown-item custom-svg-icon" routerLink="/user/trash">
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"/></svg>            
            <span>Trash</span>
          </button>
        </li>
        <li>
          <a data-cy="followed-streams-dropdown" class="menu--link" (click)="moveToFollowedStream()">
            <fa-icon [icon]="['fas', 'podcast']"></fa-icon>
            <span>Followed Streams</span>
          </a>
        </li>
        <li>
          <button data-cy="saved-dropdown" class="dropdown-item" routerLink="/user/{{user.id}}/saved-notes">
            <fa-icon [icon]="['fas', 'star']"></fa-icon>
            <span>Saved</span>
          </button>
        </li>
        <li>
          <button data-cy="team-account-dropdown" class="dropdown-item" routerLink="/team">
            <fa-icon [icon]="['fas', 'users']"></fa-icon>
            <span>Team account</span>
          </button>
        </li>
        <li>
          <button data-cy="earnings-dropdown" class="dropdown-item" routerLink="/user/{{user.id}}/earnings">
            <fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon>
            <span>Earnings</span>
          </button>
        </li>
        <li>
          <!--<button class="dropdown-item"><i class="fa fa-usd" aria-hidden="true"></i> Payments</button>-->
          <!--<button class="dropdown-item"><i class="fa fa-cogs" aria-hidden="true"></i> Security</button>-->
          <button data-cy="log-out-dropdown" class="dropdown-item" (click)="emitLogOutEvent()">
            <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
            <span>Log Out</span>
          </button>
        </li>
        <li>
          <button data-cy="saved-dropdown" class="dropdown-item" (click)="openShareModal()">
            <fa-icon [icon]="['fas', 'share']"></fa-icon>
            <span>Share</span>
          </button>
        </li>
      </ul>
    </div>
  </li>
</ul>

<share-button
  [contentElRef]="notdScreenElement"
  [shareContent]="{title: 'Check out the content on Notd.io!', message: 'Don`t wait! Just read and create your content on Notd.io for free!' }"></share-button>

<section
  class="share-img-content"
  #shareImgContent>
  <img class="share-img-content__logo" src="../../assets/logo-black.png" alt="Notd.io">
  <h1>
    Finally! A Fully Customizable, Ad-Free News and Information Network
  </h1>
  <h2>
    Notd is a revolutionary publishing network: a go-to source for unbiased news, podcasts, photography, and more.
  </h2>
</section>
