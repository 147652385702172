import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ConfirmationModalModule} from '../confirmation-modal/confirmaton-modal.module';

import {ShareComponent} from './share.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faTrashAlt, faVolumeUp} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [ ShareComponent ],
    imports: [
        RouterModule,
        CommonModule,
        ConfirmationModalModule,
        FontAwesomeModule
    ],
    exports: [ ShareComponent ]
})

export class ShareModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTrashAlt, faVolumeUp);
  }
}
