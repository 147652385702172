import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { resolve } from 'dns';
import { StreamService } from '../services/stream_service';

@Injectable()
export class StreamIdGuard  {
  constructor(
    private router: Router,
    private streamService: StreamService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params['custom-url']) {
      const customUrl = route.params['custom-url'];
      return this.streamService.getStreamIdFromCustomUrl(customUrl)
        .then(result => !/[a-zA-Z]/.test(result.channel.id))
        .catch(err => {
          console.error('Error while fetching stream custom-url data', err);
          this.router.navigate(['/']);
          return false;
        });
    } else {
      const streamId = route.params['id'];
      return this.streamService.getStreamInfo(streamId)
        .then(() => true)
        .catch(err => {
          console.error('Error while fetching stream', err);
          this.router.navigate(['/']);
          return false;
        });
    }
  }
}
