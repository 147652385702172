import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {UserService} from '../services/user_service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Util} from '../util/util';
import {StreamService} from '../services/stream_service';
import {isPlatformBrowser} from '@angular/common';
import {ContactFormModalComponent} from '../contact-form-modal/contact-form-modal.component';
import { CacheService } from '../services/cache_service';
import { NotificationButtonComponent } from '../notification-button/notification-button.component';
import { NotificationsService } from '../services/notifications_service';
import { CategoriesService } from '../services/categories_service';
import { ShareComponent } from '../share/share.component';

const LANGUAGE_CACHE = 'NOTD_LANG';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() searchBarVisibleStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() changeMobileMenuStatus: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(NotificationButtonComponent,  {static: false}) public notificationComponent: NotificationButtonComponent;
  @ViewChild(ShareComponent,  {static: false}) public shareComponent: ShareComponent;
  @ViewChild('searchModal', {static: false}) searchModal: ElementRef;
  @ViewChild('mobileMenu') mobileMenu: ElementRef<HTMLInputElement>;
  @ViewChild('mobileSubmenu') mobileSubmenu: ElementRef<HTMLInputElement>;
  @ViewChild('shareImgContent', {static: false}) public shareImgContent: ElementRef;

  isLoggedIn = false;
  user = {};
  isCollapsed = false;
  contactForm: UntypedFormGroup;
  contactMessageSent = false;
  userStreams: any[] = [];
  loadingStreams = false;
  streamsToggle = false;
  searchShowTrigger = false;
  public notificationModalToggle = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  searchFormProps: {} = {
    customClass: 'search-holder',
    showIcon: true,
    iconSide: 'right',
    showButton: false,
    inputPlaceholder: 'Search for text...',
    buttonText: ''
  };

  languages = [
    {
      key: 'en', value: 'English'
    },
    {
      key: 'es', value: 'Español'
    }
  ];

  selectedLang;
  public pwdApp = false;
  public showSearchBar = false;
  public isLoadingNoteCreator = false;
  public userHasNotification = false;
  public categories: {
    cat: string;
    label: string;
  }[] = [];

  public mobileSearchModalRef: any;
  public isMainPageUrl = false;

  public notdScreenElement: ElementRef;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static lastUser;
  private userChange;
  private communityObs$;

  constructor(
      private userService: UserService,
      private cacheService: CacheService,
      private streamService: StreamService,
      private notificationsService: NotificationsService,
      private categoryService: CategoriesService,
      private router: Router,
      private route: ActivatedRoute,
      private modalService: NgbModal,
      private fb: UntypedFormBuilder,
      private elRef: ElementRef,
      // eslint-disable-next-line @typescript-eslint/ban-types
      @Inject(PLATFORM_ID) protected platformId: Object,
      private utilities: Util) {
  }

  @HostListener('window:click', ['$event'])
  closeMobileMenu(event) {
    const mobileMenu = this.mobileMenu.nativeElement;
    const mobileSubmenu = this.mobileSubmenu.nativeElement;
      if (
        !mobileMenu.contains(event.target) &&
        !mobileSubmenu.contains(event.target) &&
        window.innerWidth <= 992 &&
        this.isCollapsed) {
        this.toggleMobileMenu();
      }

    if (event.target.classList.contains('close-m') && window.innerWidth <= 992) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  @HostListener('window:scroll', ['$event'])
  controlMobileMenu() {
    const topOffset = window.pageYOffset || document.documentElement.scrollTop;
    if (this.isCollapsed) {
      this.toggleMobileMenu();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.notdScreenElement = this.shareImgContent;
    });
  }

  public ngOnInit(): void {
    const currentLng = this.cacheService.cache.get(LANGUAGE_CACHE);
    this.selectedLang = this.languages.filter(ln => ln.key === currentLng)[0];

    this.userChange = this.userService.curUser$.subscribe(
      user => {
        this.isLoggedIn = this.userService.isLoggedIn();
        if (user && this.isLoggedIn) {
          if (user.profileImageUrl) {
            if (
              HeaderComponent.lastUser &&
              HeaderComponent.lastUser.profileImageUrl !== user.profileImageUrl) {
              user.profileImageUrl = this.utilities.uncacheUrl(user.profileImageUrl);
            }
          }
          this.user = user;
          HeaderComponent.lastUser = user;
        }
      });

    this.route.url.subscribe(() => {
      this.searchVisibility(this.router.url);
      this.isMainPageUrl = !this.router.url || this.router.url === '' || this.router.url === '/' ? true : false;
    });

    // this.createContactForm();
    if (isPlatformBrowser(this.platformId)) {
      this.showBackButtononPwdApp();

      if (!this.utilities.isTouchDevice()) {
        this.elRef.nativeElement.classList.add('scroll--header');
      }
    }

    this.notificationsService.notificationListSubject.subscribe(nList => {
      this.userHasNotification = nList && nList.length > 0;
    });
  }

  logOut() {
    this.userService.logout().then(
      response => {
        this.isLoggedIn = false;
        this.router.navigate(['/']);
      }

    ).catch (ex => {
      console.error('Error ', ex);
      this.router.navigate(['/']);
    });
  }

  public toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar;
    this.searchBarVisibleStatus.emit(this.showSearchBar);
  }

  public onShowSearchModal(): void {
    this.getCategories();
    this.mobileSearchModalRef = this.modalService.open(this.searchModal, { windowClass: 'search-modal', size: 'lg' });
  }

  public loadingNoteCreator(): void {
    this.isLoadingNoteCreator = true;
  }

  public toggleNotifications(): void {
    this.notificationComponent.toggleNotifications();
    if (this.isCollapsed) {
      this.toggleMobileMenu();
    }
  }

  public mobileSearchTriggered(ev: any): void {
    this.mobileSearchModalRef.close();
  }

  public toggleMobileMenu(): void {
    this.isCollapsed = !this.isCollapsed;
    this.changeMobileMenuStatus.emit(this.isCollapsed);
  }

  public openShareModal(): void {
    this.shareComponent.showShareModal();
  }

  openContactModal() {
    const modalRef = this.modalService.open(ContactFormModalComponent);
  }

  myStreamsToggle(forceAction?: boolean) {
    this.streamsToggle = (forceAction) ? forceAction : !this.streamsToggle;
    if (this.streamsToggle) {
      this.getMyStreams();
    }
  }

  searchVisibility(url) {
    this.searchShowTrigger = (url === '/' || url.indexOf('OAuthCode') > 0 || url.indexOf('search') > 0) ? false : true;
  }

  getMyStreams() {
    this.loadingStreams = true;
    this.userStreams = [];
    this.streamService.getUserStreams().then(resp => {
      let uStreams = resp;

      uStreams.forEach(st => {
        st._community = false;
      });

      if (this.communityObs$) {
        this.communityObs$.unsubscribe();
      }

      this.communityObs$ = this.userService.listCommunities().subscribe(
          comList => {
            if (comList.length > 0) {
              uStreams = uStreams.concat(comList);
            }
            this.userStreams = uStreams;
            this.loadingStreams = false;

            comList.forEach((str: any) => {
                str._community = true;
            });

          },
          err => {
            this.loadingStreams = false;
          }
      );
    },

    err => {
      this.userStreams = undefined;
      this.loadingStreams = false;
    });
  }

  selectLanguage(language) {
    this.cacheService.setCache(LANGUAGE_CACHE, language.key);
    location.reload();
  }

  showBackButtononPwdApp() {
    this.pwdApp = (window.matchMedia('(display-mode: standalone)').matches && this.router.url !== '/') ? true : false;
  }

  historyBack() {
    console.log('history back');
    window.history.go(-1);
  }

  ngOnDestroy() {
    if (this.userChange) {
      this.userChange.unsubscribe();
    }

    if (this.communityObs$) {
      this.communityObs$.unsubscribe();
    }
  }

  private getCategories(): void {
    const categories = this.categoryService.getCategories();
    const cateoriesByLabel = [];

    for (const c of categories) {
      cateoriesByLabel.push({
        cat: c,
        label: this.categoryService.getCategoryLabel(c)
      });
    }
    this.categories = cateoriesByLabel;
  }
}
