import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {HeaderComponent} from './header.component';
import {LoginModule} from '../../shared/transact-auth/transact-auth.module';
import {SearchContentModule} from '../../shared/search/search.module';
import {NotificationBarModule} from '../notification-bar/notification-bar.module';
import {NotificationButtonModule} from '../notification-button/notification-button.module';
import {AuthenticatedUserNavbarElementModule} from '../user-nav/user-nav.module';
import {PipesModule} from '../pipes/pipes.module';
import {UserEarningsCounterModule} from '../user-earnings-counter/user-earnings-counter.module';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { faAngleDoubleLeft, faAngleRight, faBars, faSync, faSyncAlt, faUserPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import {ContactFormModalModule} from '../contact-form-modal/contact-form-modal.module';
import {CreateNoteModule} from '../create-note-component/create-note.module';
import { NotdPartnersModalModule } from '../notd-partners-modal/notd-partners-modal.module';
import { ShareModule } from '../share/share.module';

@NgModule({
    declarations: [ HeaderComponent ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NotificationBarModule,
        NotificationButtonModule,
        NgbModule,
        PipesModule,
        AuthenticatedUserNavbarElementModule,
        SearchContentModule,
        LoginModule,
        FontAwesomeModule,
        UserEarningsCounterModule,
        ContactFormModalModule,
        CreateNoteModule,
        NotdPartnersModalModule,
        ShareModule
    ],
    exports: [ HeaderComponent ]
})

export class HeaderModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faBars, faAngleRight, faSyncAlt, faUserPlus, faAngleDoubleLeft, faSync, faCircleNotch);
  }
}
