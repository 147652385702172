import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NoteCreatorComponent} from '../note-detailed/creator/note-creator.component';

@Injectable()
export class ConfirmNoteDeactivateGuard  {
    canDeactivate(component: NoteCreatorComponent,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState: RouterStateSnapshot) {
        if (component.checkForNoteChanges()) {
            component.showDeactivateNoteModal(nextState);
            return false;
        } else {
            return true;
        }
    }
}
