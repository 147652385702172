import { isPlatformBrowser } from '@angular/common';
import { Component, AfterViewInit, PLATFORM_ID, Inject, Input } from '@angular/core';
import * as CookieConsent from 'vanilla-cookieconsent';
import { AnalyticsService } from '../services/analytics_service';

@Component({
  selector: 'cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements AfterViewInit {
  @Input() displayDelay: number;

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const setConsent = (categories: string[]) => {
            const consent: any = {}; 
            if (categories.includes('necessary')) {
              consent.functionality_storage = 'granted';
              consent.security_storage = 'granted';
            }
            if (categories.includes('ads')) {
              consent.ad_personalization = 'granted';
              consent.ad_storage = 'granted';
              consent.ad_user_data = 'granted';
            }
            if (categories.includes('analytics')) {
              consent.functionality_storage = 'granted';
              consent.analytics_storage = 'granted';
              consent.personalization_storage = 'granted';
            }
            return consent;
          };
      
        CookieConsent.run({
            onFirstConsent: ({ cookie }) => {
              console.log('onFirstConsent fired', cookie);
            },
      
            onConsent: ({ cookie }) => {
              const { categories } = cookie;
              console.log('onConsent fired!', cookie);
              this.analyticsService.updateConsent(setConsent(categories));
            },
      
            onChange: ({ changedCategories, cookie }) => {
              const { categories } = cookie;
              console.log('onChange fired!', changedCategories, cookie);
              this.analyticsService.updateConsent(setConsent(categories));
            },
      
            categories: {
              necessary: {
                enabled: true,
                readOnly: true,
              },
              analytics: {
                autoClear: {
                  cookies: [
                    {
                      name: /^_ga/,
                    },
                    {
                      name: '_gid',
                    },
                  ],
                },
              },
              ads: {},
            },
      
            language: {
              default: 'en',
              translations: {
                en: {
                  consentModal: {
                    title: 'Cookie Consent',
                    description:
                      'By clicking “Allow All”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing',
                    acceptAllBtn: 'Allow all',
                    acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences',
                    footer: `
                      <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    `,
                  },
                  preferencesModal: {
                    title: 'Manage cookie preferences',
                    acceptAllBtn: 'Allow all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Allow current selection',
                    closeIconLabel: 'Close modal',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                      {
                        title: 'Your Privacy Choices',
                        description:
                          `We use cookies to personalize content and to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.`,
                      },
                      {
                        title: 'Strictly necessary cookies (Always Active)',
                        description:
                          'These are required first party cookies to login to the site. Without these cookies, your request cannot be properly delivered.',
                        linkedCategory: 'necessary',
                      },
                      {
                        title: 'Performance and Analytics',
                        description:
                          'These cookies are set to provide quantitative measures of website visitors. Information collected with these cookies is used in operations to measure website or software performance. With these cookies we are able to count visits and traffic sources to improve the performance of our site.',
                        linkedCategory: 'analytics',
                        cookieTable: {
                          caption: 'Cookie table',
                          headers: {
                            name: 'Cookie',
                            domain: 'Domain',
                          },
                          body: [
                            {
                              name: '_ga',
                              domain: window.location.hostname,
                            },
                            {
                              name: '_gid',
                              domain: window.location.hostname,
                            },
                          ],
                        },
                      },
                      {
                        title: 'Marketing Cookies',
                        description:
                          'These cookies are set by our advertising partners. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers.',
                        linkedCategory: 'ads',
                      },
                    ],
                  },
                },
              },
            },
        });
    }, this.displayDelay | 0);
  }
}
