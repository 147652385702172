<a
  class="share-button__link type--button"
  title="text"
  (click)="showShareModal()">
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g id="Share"><path d="M50,40a9.9827,9.9827,0,0,0-7.7414,3.68l-18.62-9.0542a9.7164,9.7164,0,0,0,0-5.2514l18.62-9.0542A10.069,10.069,0,1,0,40.4,16.7757L21.834,25.8036a10,10,0,1,0,0,12.3928L40.4,47.2243A9.9959,9.9959,0,1,0,50,40ZM50,8a6,6,0,1,1-5.24,8.9152c-.0046-.01-.0064-.02-.0112-.03-.0139-.0285-.0339-.0516-.0489-.0792A5.9943,5.9943,0,0,1,50,8ZM14,38a6,6,0,1,1,6-6A6.0066,6.0066,0,0,1,14,38ZM50,56a5.9962,5.9962,0,0,1-5.288-8.83c.01-.02.0271-.0342.037-.0546.0048-.01.0066-.02.0112-.03A5.9984,5.9984,0,1,1,50,56Z"/></g></svg>
    Share
</a>

<ng-template #shareButtonModal let-c="close" let-d="dismiss">
  <a (click)="d('Cross click')" data-cy="close-modal" class="share-button-modal__close-btn" title="Close Modal">
      <svg enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
  </a>
  <h2 class="share-button-modal__title">
    Share
  </h2>
  <section
    [hidden]="showError.show"
    class="share-button-modal__content" #shareContentArea> 
  </section>
  <section
    class="share-button-modal__error"
    *ngIf="showError.show">
    <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
    <p>{{showError.message}}</p>
  </section>
  <section class="share-button-modal__buttons">
    <button
      *ngIf="!showError.show"
      type="button"
      class="notd__button--v2 button--black" (click)="initShare()">Share page</button>
    <button
      *ngIf="!showError.show"
      type="button"
      class="notd__button--v2 button--black" (click)="captureElementAsImage()">Save image</button>
    <button
      type="button"
      class="notd__button--v2 button--black" (click)="d('Cross click');">Close</button>
  </section>
</ng-template>