import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { catchError, filter, retry, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export type ConsentTypes =
  | 'ad_personalization'
  | 'ad_storage'
  | 'ad_user_data'
  | 'analytics_storage'
  | 'functionality_storage'
  | 'personalization_storage'
  | 'security_storage';

export type ConsentPayload = Partial<{
  [key in ConsentTypes]: 'granted' | 'denied';
}>;

@Injectable()
export class AnalyticsService {
  constructor(
      private http: HttpClient,
      private router: Router,
      @Inject(PLATFORM_ID) private platformId
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
      });
    }
  }

  public updateConsent(update: ConsentPayload): void {
    if (isPlatformBrowser(this.platformId)) {
      this.gtag('consent', 'update', update);
    }
  }

  public sendUtmTags(tags: {[key: string]: string}): void {
    if (!tags || Object.keys(tags).length < 1) {
      // don't send if empty
      return;
    }

    const url = '/api/util/utm/tags';
    this.http.post(url, tags)
      .pipe(
        retry(2), // retry a failed request up to 2 times
        catchError( (err: any) => {
          console.error('Error put utm', err);
          return null;
        }))
      .subscribe();
  }

  private gtag(..._args: any[]): void {
    if (isPlatformBrowser(this.platformId) && (window as any).dataLayer) {
      // eslint-disable-next-line prefer-rest-params
      (window as any).dataLayer.push(arguments);
    }
  }
}
