import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@sentry/types/types/user';
import { resolve } from 'dns';
import { StreamService } from '../services/stream_service';
import { UserService } from '../services/user_service';
import { Stream } from '../types/stream';

export interface OwnedStreams {
  [key: string]: string;
}
@Injectable()
export class StreamOwnerGuard  {
  constructor(
    private router: Router,
    private streamService: StreamService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const streamId = route.params['id'];
    return this.userService.getProfile().then((user: User) => {
      const streamsOwned = user.chOwned;

      if (route.params['custom-url']) {
        return this.streamService
          .getStreamIdFromCustomUrl(route.params['custom-url'])
          .then(result => {
            return this.checkForOwnedStream(result.channel.id, streamsOwned, state);
          })
          .catch(err => {
            console.error('Error while fetching stream custom-url data', err);
            this.router.navigate(['/']);
            return false;
          });
      } else {
        return this.streamService.getStreamInfo(streamId)
          .then(result => {
            return this.checkForOwnedStream(result.id, streamsOwned, state);
          })
          .catch(err => {
            console.error('Error while fetching stream', err);
            this.router.navigate(['/']);
            return false;
          });
      }
    }, () => {
      return false;
    });
  }

  private checkForOwnedStream(streamId : string | number, userStreams: OwnedStreams, state: RouterStateSnapshot): boolean {
    for (const [key, value] of Object.entries(userStreams)) {
      if (value === streamId) {
        return true;
      }
    }
    return false;
  }
}
