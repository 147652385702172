import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import {StreamService} from '../services/stream_service';
import {UserService} from '../services/user_service';

import {Util} from '../../shared/util/util';
import { ShareComponent } from '../share/share.component';

@Component({
  selector: 'authenticated-user-navbar-element',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.scss']
})

export class AuthenticatedUserNavbarElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('shareImgContent', {static: false}) public shareImgContent: ElementRef;
  @ViewChild(ShareComponent,  {static: false}) public shareComponent: ShareComponent;

  @Input() user: any;
  @Input() isLoggedIn: boolean;
  @Output() logOut: EventEmitter<boolean> = new EventEmitter();
  public canCreateStream = true; // READERS and PUBLISHERS can both create stream
  userStreams: any[];
  notifications: any[] = [];
  loadingStreams;
  avatar$;

  public notdScreenElement: ElementRef;

  private updatedStream$;
  private communityObs$;
  private paramsObs$;

  constructor(
    private userService: UserService,
    private router: Router,
    private streamService: StreamService,
    private route: ActivatedRoute,
    private utilities: Util
    ) { }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.notdScreenElement = this.shareImgContent;
    });
  }

  public ngOnInit(): void {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.avatar$ = this.utilities.userAvatar$.subscribe(avatar => {
      if (this.user) {
        this.user.profileImageUrl = avatar;
      }
    });

    this.updatedStream$ = StreamService.streamsUpdated.subscribe(stream => {
      this.refreshStreams(stream);
    });

    this.refreshStreams();

    this.paramsObs$ = this.route.queryParams.subscribe((queryParams: any) => {
      const code = queryParams['code'];

      if (code && code.startsWith('OAuthCode')) {
        const newQueryParams = {};

        for (const k in queryParams) {
          if (k !== 'code' && k !== 'state') {
            newQueryParams[k] = queryParams[k];
          }
        }

        this.router.navigate([], { queryParams: newQueryParams });
      }
    });
  }

  public openShareModal(): void {
    this.shareComponent.showShareModal();
  }

  showStreams(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    this.router.navigate(['user', 'followed-streams']);
  }

  userStreamsClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  emitLogOutEvent() {
    this.logOut.emit(true);
  }

  public moveToFollowedStream(): void {
    this.router.navigate(['user', 'followed-streams']);
  }

  refreshStreams(stream?: any) {
    this.loadingStreams = true;
    this.userStreams = [];

    this.streamService.getUserStreams().then(
      resp => {
        let uStreams = resp;

        uStreams.forEach(st => {
          st._community = false;
        });

        if (this.communityObs$) {
          this.communityObs$.unsubscribe();
        }

        this.communityObs$ = this.userService.listCommunities().subscribe(comList => {
          if (comList.length > 0) {
            uStreams = uStreams.concat(comList);
          }

          this.userStreams = uStreams;
          this.loadingStreams = false;
        },
        err => {
          this.loadingStreams = false;
        }
        );
      },
      err => {
        this.userStreams = undefined;
        this.loadingStreams = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.updatedStream$) {
      this.updatedStream$.unsubscribe();
    }
    if (this.communityObs$) {
      this.communityObs$.unsubscribe();
    }
    if (this.paramsObs$) {
      this.paramsObs$.unsubscribe();
    }
    if (this.avatar$) {
      this.avatar$.unsubscribe();
    }
  }
}
