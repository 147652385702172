import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'share-button',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent {
  @ViewChild('shareButtonModal', {static: false}) public shareButtonModal: ElementRef;

  @Input()
  public shareContent: {title: string; message: string; label: string};

  @Input()
  public contentElRef!: ElementRef;

  @Output()
  public transformClassStylesAsInline: EventEmitter<null> = new EventEmitter();

  public showError: {show: boolean; message: string | null} = {
    show: false,
    message: ''
  };
  public contentInjected = false;
  private shareModalRef: NgbModalRef;

  constructor(
    private renderer: Renderer2,
    private modalService: NgbModal) {}

  public showShareModal(): void {
    this.resetModalErrors();

    this.shareModalRef = this.modalService.open(this.shareButtonModal, {
      windowClass: 'share-button-modal',
      size: 'lg'
    });

    if (this.contentElRef) {
      this.injectElement();
    }

    this.shareModalRef.result.then(() => {
      console.log('Modal closed!');
    }, () => {
      console.log('Modal closed!');
    });
  }

  public hideShareModal(): void {
    this.shareModalRef.close();
  }

  public captureElementAsImage(): void {
    const targetElement = document.querySelector('ngb-modal-window .share-img-content') as HTMLElement;

    if (targetElement) {
      htmlToImage.toCanvas(targetElement, {}).then(canvas => {
        const resizedCanvas = document.createElement('canvas');
        const ctx = resizedCanvas.getContext('2d');
  
        const targetWidth = 1080;
        const targetHeight = 1920;
  
        resizedCanvas.width = targetWidth;
        resizedCanvas.height = targetHeight;
  
        const scaleX = targetWidth / canvas.width;
        const scaleY = targetHeight / canvas.height;
        const scale = Math.min(scaleX, scaleY); // Maintain aspect ratio
  
        const offsetX = (targetWidth - (canvas.width * scale)) / 2;
        const offsetY = (targetHeight - (canvas.height * scale)) / 2;
  
        ctx.drawImage(canvas, offsetX, offsetY, canvas.width * scale, canvas.height * scale);
  
        const imgData = resizedCanvas.toDataURL('image/png');
  
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'notd-screenshot.png';
  
        document.body.appendChild(link);
        link.click();
  
        document.body.removeChild(link);
      }).catch(error => {
        console.error('Error capturing the element:', error);
      });
    }
  }

  public async initShare(): Promise<void> {
    if (navigator.share) {
      try {
        const targetElement = document.querySelector('ngb-modal-window .share-img-content') as HTMLElement;
        const canvas = await htmlToImage.toCanvas(targetElement);
        const dataUrl = canvas.toDataURL('image/png');
        const blob = await (await fetch(dataUrl)).blob();
        const file = new File([blob], 'notd-screenshot.png', { type: blob.type });

        await navigator.share({
          title: this.shareContent.title || 'Check out this amazing content on Notd.io!',
          text: this.shareContent.message || 'Here is some awesome content I wanted to share with you from Notd.io.',
          files: [file],
        });
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      this.showError = {
        show: true,
        message: 'Your browser is not supported to use share functionality. Please try with different browser.'
      }
      console.error('Share API is not supported in your browser.');
    }
  }

  public resetModalErrors(): void {
    this.showError = {
      show: false,
      message: '',
    }
  }

  private injectElement(): void {
    const targetElement = document.querySelector('.share-button-modal__content');
  
    if (targetElement && this.contentElRef && this.contentElRef.nativeElement) {
      while (targetElement.firstChild) {
        this.renderer.removeChild(targetElement, targetElement.firstChild);
      }
  
      const clonedElement = this.contentElRef.nativeElement.cloneNode(true) as HTMLElement;
      this.copyStyles(this.contentElRef.nativeElement as HTMLElement, clonedElement);
      this.convertImgsToBase64(clonedElement).then(() => {
        clonedElement.style.display = 'flex';
        this.renderer.appendChild(targetElement, clonedElement);
        this.contentInjected = true;
      });
    }
  }

  private copyStyles(sourceNode: HTMLElement, targetNode: HTMLElement): void {
    if (!(sourceNode instanceof Element) || !(targetNode instanceof Element)) {
      console.error('Both sourceNode and targetNode must be DOM elements.');
      return;
    }
  
    const computedStyle = getComputedStyle(sourceNode);
    
    for (let i = 0; i < computedStyle.length; i++) {
      const key = computedStyle[i];
      this.renderer.setStyle(targetNode, key, computedStyle.getPropertyValue(key));
    }
  
    const sourceChildren = Array.from(sourceNode.children) as HTMLElement[];
    const targetChildren = Array.from(targetNode.children) as HTMLElement[];
  
    if (sourceChildren.length === targetChildren.length) {
      sourceChildren.forEach((sourceChild, index) => {
        this.copyStyles(sourceChild, targetChildren[index]);
      });
    } else {
      console.error('Mismatch in number of children between sourceNode and targetNode.');
    }
  }

  private async convertImgsToBase64(element: any): Promise<any> {
    const images = element.querySelectorAll('img');

    for (const i of images) {
      if (!i.getAttribute('data-status') && !i.getAttribute('src').indexOf('https')) {
        this.convertToBase64(i.getAttribute('src')).then(newImgSrc => {
          i.setAttribute('src', newImgSrc);
          i.setAttribute('data-status', 'b64');
        });
      }
    }
  }

  private convertToBase64(imgSrc: string): Promise<any> {
    const uri = encodeURIComponent(imgSrc);
    return fetch(`/api/util/fetch/data?url=${uri}`)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }))
      .catch(error => console.error('Error fetching data:', error));
  }
}

